module services {
    export module salesorder {
        export class salesOrderService implements interfaces.sales.ISalesOrderService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }           

            getSalesOrderList(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderDisplay> {
                return this.$resource<interfaces.sales.ISalesOrderDisplay>(this.ENV.DSP_URL + "SalesOrder/GetOrders", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }

            GetSalesOrderPRListExcel(soId: number, showOnlyProhibited: boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'SalesOrder/GetSalesOrderPRListExcel?soId=' + soId + '&showOnlyProhibited=' + showOnlyProhibited;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getSalesOrderListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'SalesOrder/GetOrdersExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));
                
                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            GetSalesOrderDetailSKUListExcel(soId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'SalesOrder/GetSalesOrderDetailSKUListExcel?soId=' + soId;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            //Get a Sales Order By detail
            getSalesOrder(): ng.resource.IResourceClass<interfaces.sales.ISalesOrder> {
                return this.$resource<interfaces.sales.ISalesOrder>(this.ENV.DSP_URL + "SalesOrder/GetSalesOrder", {
                }, {
                        query: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: false
                        }
                    });
            }

            ViewDetailPR(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderPRDisplay> {
                var viewDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return this.$resource<interfaces.sales.ISalesOrderPRDisplay>(this.ENV.DSP_URL + "SalesOrder/ViewDetailPR", {
                    soId: '@soId',
                    showOnlyProhibited: '@showOnlyProhibited',
                }, {
                    query: viewDetail
                });
            }

            //Generate FEC / UCR Number
            generateFECNumber(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/GenerateFECNumber", {
                        id: '@id'
                    });
            }

            //Refresh Comments
            refreshComments(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/RefreshComments", {
                    id: '@id'
                });
            }

            //Cost Sales Order
            costSalesOrder(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/CostSalesOrder", {
                    id: '@id',
                    costModelId : '@costModelId'
                });
            }

            //Refresh SundryItems

            refreshSundryItems(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/RefreshSundryItems", {
                    id: '@id'
                });
            }

            refreshTariffCodeAndOrigin(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/RefreshTariffCodeAndOrigin", {
                    id: '@id'
                });
            }

            CreateNewVersion(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/CreateNewVersion", {
                    id: '@id',
                    functionClassificationValueId: '@functionClassificationValueId',
                    reason: '@reason'
                });
            }

            //Saves SO
            saveSalesOrder(): ng.resource.IResourceClass<interfaces.sales.ISalesOrder> {
                var saveOrder: ng.resource.IActionDescriptor = {
                    method: 'POST'
                }

                return this.$resource<interfaces.sales.ISalesOrder>(this.ENV.DSP_URL + "SalesOrder/Save", {                }, {
                        query: saveOrder
                    });
            }

            //Save SO and Recalcuate Exchange Rae
            recalculateExchangeRate(): ng.resource.IResourceClass<interfaces.sales.ISalesOrder> {
                var saveOrder: ng.resource.IActionDescriptor = {
                    method: 'POST'
                }

                return this.$resource<interfaces.sales.ISalesOrder>(this.ENV.DSP_URL + "SalesOrder/RecalculateExchangeRate", {}, {
                    query: saveOrder
                });
            }

            //Called by "Create New" button
            create(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/Create", {
                    OwnerEntityId: '@OwnerEntityId',
                    TypeId: '@TypeId',
                    SupplierBranchId: '@SupplierBranchId',
                    CustomerBranchId: '@CustomerBranchId',
                    ConsigneeBranchId: '@ConsigneeBranchId',
                    SOCounterId: '@SOCounterId',
                    CustomNumber: '@CustomNumber',
                    soId: '@soId'
                });
            }

            changeStatus(): ng.resource.IResourceClass<interfaces.sales.ISalesOrder> {
                return this.$resource<interfaces.sales.ISalesOrder>(this.ENV.DSP_URL + "SalesOrder/ChangeStatus", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "SalesOrder/GetDropdownList", {
                    OwnerEntityId: '@OwnerEntityId',
                    searchText: '@searchText'              
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            //Route Id and ConsignmenId are optional. Set to 0 if not required.
            getSalesOrderListForCI(ownerEntityId: number, consignmentTypeId: number, supplierId: number, customerId: number, consigneeId: number, routeId: number, consignmentId: number): ng.resource.IResourceClass<interfaces.sales.ISalesOrderForCommercialInvoice> {
                return this.$resource<interfaces.sales.ISalesOrderForCommercialInvoice>(this.ENV.DSP_URL + "Consignment/GetSalesOrderForCI", {
                    ownerEntityId: ownerEntityId,
                    consignmentTypeId: consignmentTypeId,
                    supplierId: supplierId,
                    customerId: customerId,
                    consigneeId: consigneeId,
                    routeId: routeId,
                    consignmentId: consignmentId
                });
            }

            getCostingLineListForMultipleCostings(): ng.resource.IResourceClass<interfaces.costing.ICostingLineModel> {
                return this.$resource<interfaces.costing.ICostingLineModel>(this.ENV.DSP_URL + "SalesOrder/GetCostingLineListForMultipleCostings", {
                    id: '@id',
                }, {
                        query: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            isArray: true
                        }
                    });
            }

            Submit(ownerEntityId: number,typeId: number,shipperId: number,consigneeId: number,routeCodeId: number,customNumber: string,counterId: number,isInbound?: boolean,orderIdList?: number[]): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/Submit", {
                    ownerEntityId: ownerEntityId,
                    typeId: typeId,
                    shipperId: shipperId,
                    consigneeId: consigneeId,
                    routeCodeId: routeCodeId,
                    customNumber: customNumber,
                    counterId: counterId,
                    isInbound: isInbound,
                    orderIdList: orderIdList
                });
            }

            CheckForPR(soId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/CheckForPR", {
                    soId: soId,
                });
            }

            RunQuotaAllocation(soId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/RunQuotaAllocation", {
                    soId: soId,
                });
            }

            GetPermitsForDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "SalesOrder/GetPermitsForDropdown", {
                    salesOrderRestrictionId: '@salesOrderRestrictionId',
                    searchText: '@searchText'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
           
            linkChildOrder(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrder/LinkChildOrder", {
                    parentSalesOrderId: '@parentSalesOrderId',
                    childSalesOrderId : '@childSalesOrderId'
                });
            }

            getChildOrdersList(): ng.resource.IResourceClass<interfaces.sales.ISalesOrderChildOrder> {
                return this.$resource<interfaces.sales.ISalesOrderChildOrder>(this.ENV.DSP_URL + "SalesOrder/GetChildOrdersList", {
                    salesOrderId: '@salesOrderId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("salesOrderService", services.salesorder.salesOrderService);
}